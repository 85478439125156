(function(window, document) {
  // The main object the client interacts with.
  window.spreadshirt = window.spreadshirt || {};
  var spreadshirt = window.spreadshirt;
  spreadshirt.create = function(type, options, callback) {
    options = options || {};
    callback = callback || function() {};
    designer(type.toLowerCase(), options, callback);
  };

  // A channel manages the request/response workflow between iframe and
  // parent window. Basically, a postMessage is sent to the iframe, which
  // in turns responds with a postMessage, upon which the registered
  // callback is invoked in the parent window with the response data.
  function createChannel(window, targetWindow) {
    // the message id is a token sent with the request and returned with
    // the response to be able to map the response to the correct callback
    var messageId = 0;
    var callbackCache = {};

    var call = function(method, data, callback) {
      var id = ++messageId;
      callbackCache[id] = callback;
      var message = {
        messageId: id,
        method: method,
        data: data,
      };
      targetWindow.contentWindow.postMessage(message, '*');
    };

    function receiveChannelResponse(event) {
      var callback = callbackCache[event.data.messageId];
      if (!callback || event.source !== targetWindow.contentWindow) {
        // we haven't requested this message
        return;
      }
      delete callbackCache[event.data.messageId];
      callback && callback(event.data.error, event.data.data);
    }

    window.addEventListener('message', receiveChannelResponse, false);
    return call;
  }

  // the main bootstrapping function. sets up the iframe with the correct url, registers
  // message handlers and returns the remote control for the designer.
  function designer(type, options, bootstrapped) {
    var platform = options.platform === 'NA' ? 'NA' : 'EU';
    var url =
      options.url ||
      '//designer.spreadshirt.' + (platform === 'EU' ? 'net' : 'com') + '/designers/' + type;

    // locale url param is used by Designer API to determine the correct CYO D2C shop
    if (options.locale) {
      if (url.indexOf('?') > -1) {
        url += '&locale=' + options.locale;
      } else {
        url += '?locale=' + options.locale;
      }
    }

    // shopId url param is used by Designer API to set contextId of designer and not default to CYO D2C
    if (options.shopId) {
      if (url.indexOf('?') > -1) {
        url += '&shopId=' + options.shopId;
      } else {
        url += '?shopId=' + options.shopId;
      }
    }

    if (options.embroidery) {
      if (url.indexOf('?') > -1) {
        url += '&embroidery=' + options.embroidery;
      } else {
        url += '?embroidery=' + options.embroidery;
      }
    }

    // userId url param is used by Designer API to set context to 'user' if no shopId present
    if (options.userId) {
      if (url.indexOf('?') > -1) {
        url += '&userId=' + options.userId;
      } else {
        url += '?userId=' + options.userId;
      }
    }

    // as we cannot pass functions via post message, we need to wrap those
    var callbackProxies = {};
    Object.getOwnPropertyNames(options).forEach(function(key) {
      if (options[key] instanceof Function) {
        callbackProxies[key + 'Proxy'] = options[key];
        // let the client know that it should build a proxy for it
        options[key] = key + 'Proxy';
      }
    });

    // this is the main message handler for callbacks from the designer
    window.addEventListener('message', receiveMessage, false);

    var iFrame = document.createElement('iframe');
    iFrame.id = type;
    iFrame.setAttribute('width', options.width || '100%');
    iFrame.setAttribute('height', options.height || '700px');
    iFrame.setAttribute('style', 'border: 0');
    iFrame.src = url;

    var target = options.target || document.body || document.getElementsByTagName('body')[0];
    target.appendChild(iFrame);
    delete options.target;

    function bootStrap() {
      var channel = createChannel(window, iFrame);
      // send bootstrap message to iframe and wait for a list of remote-controllable methods
      channel('bootStrap', options, function(err, methods) {
        // This is the object that will be returned to the client, containing all externally available
        // methods to remote-control the Designer.
        var remoteControl = {};
        if (!err && methods) {
          methods.forEach(function(method) {
            remoteControl[method] = function() {
              var args = Array.prototype.slice.call(arguments);
              var lastArg = args[args.length - 1];
              // each remote controlled function can take a callback, which is always the last argument
              var callback = null;
              if (Object.prototype.toString.call(lastArg) === '[object Function]') {
                callback = lastArg;
                args.pop();
              }

              // send the call over the channel and invoke callback with response
              var message = { method: method, params: args };
              channel('invokeExternalMethod', message, function(error, response) {
                var result = response || [];
                result.unshift(err);
                callback && callback.apply(null, result);
              });
            };
          });
        }
        bootstrapped(err, remoteControl);
      });
    }

    // the main message handler for callbacks invoked inside of the iframe
    function receiveMessage(event) {
      if (event.source !== iFrame.contentWindow) {
        return;
      }

      var message = event.data;
      if (message === 'initialized') {
        // iframe has loaded and is ready to receive post messages
        bootStrap();
      } else {
        // some callback from inside the iframe
        var proxyName = message.method;
        var callback = callbackProxies[proxyName];

        if (callback) {
          var params = message.data;
          params.push(function(err) {
            var args = Array.prototype.slice.call(arguments) || [];
            args.shift();

            // send response back notify iframe that processing the callback is complete
            var response = {
              messageId: message.messageId,
              error: err,
              data: args,
            };
            return event.source.postMessage(response, '*');
          });
          // actually call callback
          callback.apply(null, params);
        }
      }
    }
  }
})(window, document);
